import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule} from "@angular/forms";
import {DatePipe} from "@angular/common";

//Import Data services
import { IonicStorageModule } from '@ionic/storage-angular';
import { RestaurantService } from './services/restaurant.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot()
  ],
  providers: [{
    provide: RouteReuseStrategy, useClass: IonicRouteStrategy
  },
    DatePipe,
    RestaurantService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
