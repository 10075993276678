import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./navigation/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./authentication/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'kitchen-mezzanine',
    loadChildren: () => import('./spaces/kitchen-mezzanine/kitchen-mezzanine.module').then( m => m.KitchenMezzaninePageModule)
  },
  {
    path: 'kitchen-game-room',
    loadChildren: () => import('./spaces/kitchen-game-room/kitchen-game-room.module').then( m => m.KitchenGameRoomPageModule)
  },
  {
    path: 'kitchen-private-room',
    loadChildren: () => import('./spaces/kitchen-private-room/kitchen-private-room.module').then( m => m.KitchenPrivateRoomPageModule)
  },
  {
    path: 'billing',
    loadChildren: () => import('./settings/billing/billing.module').then( m => m.BillingPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
