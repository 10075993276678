// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//Local environment
// apiURL: 'https://appdiscoverypark.dev/api/',
// publicURL: 'https://appdiscoverypark.dev/',

//Production environment
//apiURL: 'https://api.discoveryparkapp.com/api/',
//publicURL: 'https://api.discoveryparkapp.com',

export const environment = {
  production: false,
  apiURL: 'https://api.discoveryparkapp.com/api/',
  publicURL: 'https://api.discoveryparkapp.com',
  stripePublicKey: 'pk_test_51ORJAuHWvhZ7A7T16iOCupfE9W3j4PRGqMwMd9F9qqyRjxFZiuC9gV0Ou8L7yla2i9EWH4kwFo3A1svypeWhkIQV00Qfe9EdNE'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
