import { Injectable } from '@angular/core';
import { BehaviorSubject} from "rxjs";

export class RestaurantService {
  private cart: Cart = <Cart>{
    restaurant: 'park',
    items: [],
    total: 0,
    pendingItem: ''
  };

  private orderSource = new BehaviorSubject(this.cart);
  currentOrder = this.orderSource.asObservable();

  private modifierGroups = {};
  private modifierGroupsSource = new BehaviorSubject(this.modifierGroups);
  currentModifierGroup = this.modifierGroupsSource.asObservable();

  private modifierOptions = {};
  private modifierOptionsSource = new BehaviorSubject(this.modifierOptions);
  currentModifierOptions = this.modifierOptionsSource.asObservable();

  private menuGroups = {};
  private menuGroupsSource = new BehaviorSubject(this.menuGroups);
  currentMenuGroups = this.menuGroupsSource.asObservable();

  private menuItems = {};
  private menuItemsSource = new BehaviorSubject(this.menuItems);
  currentMenuItems = this.menuItemsSource.asObservable();

  constructor() { }

  //Ability To change global Cart.
  setOrder(order: Cart){
    this.orderSource.next(order);
  }
  //Ability to change global Modifier Groups.
  setModifierGroups(modifierGroups){
    this.modifierGroupsSource.next(modifierGroups);
  }
  //Ability to change global modifierOptions.
  setModifierOptions(modifierOptions){
    this.modifierOptionsSource.next(modifierOptions);
  }
  setMenuGroups(menuGroups){
    this.menuGroupsSource.next(menuGroups);
  }
  //Ability to change global menu items.
  setMenuItems(menuItems){
    this.menuItemsSource.next(menuItems);
  }
}

export interface Cart {
  couponID: any;
  couponOrderItem: any;
  restaurant: string;
  items: any[];
  total: number;
  //Items placed as pending before being added to the cart
  pendingItem: any;
}
