import {Component, HostListener} from '@angular/core';
import {Cart, RestaurantService} from "./services/restaurant.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private restaurantService: RestaurantService) {
  }
  ngOnInit(){
  }
}
